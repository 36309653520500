.dice-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 200px;
}

.dice-button {
    position: relative;
    top: 350px;
    left: 10px;
    padding: 5px 40px;
    color: #fff;
    background-color: #f4d35e;
    border: none;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 1px 3px #50514F;
    outline: none;
    transition: .3s;
}

.dice-button:hover,
.dice-button:active {
    outline: none;
    background: #50514F;
    cursor: pointer;
    transform: translateY(15px);
}

.game {
    position: relative;
    width: auto;
    height: 70%;
}

.dice {
    position: relative;
    width: 100px;
    height: 100px;
    transform-style: preserve-3d;
    transition: transform 1s;
}

.dot {
    position: absolute;
    width: 10px;
    height: 10px;
    margin: -4px -5px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: inset 2px 2px #fff;

}

.dice-one {
    position: absolute;
    left: 150px;
}

.dice-two {
    position: absolute;
    left: -80px;

}
.dice-three {
    position: absolute;
    left: 30px;

}

.side {
    position: absolute;
    background-color: #f4d35e;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    border: 1px solid goldenrod;
    text-align: center;
    line-height: 2em;
}

.side:nth-child(1) {
    transform: translateZ(3.1em);
}

.side:nth-child(6) {
    transform: rotateY(90deg) translateZ(3.1em);
}

.side:nth-child(3) {
    transform: rotateY(-90deg) translateZ(3.1em);
}

.side:nth-child(4) {
    transform: rotateX(90deg) translateZ(3.1em);
}

.side:nth-child(5) {
    transform: rotateX(-90deg) translateZ(3.1em);
}

.side:nth-child(2) {
    transform: rotateY(-180deg) translateZ(3.1em);
}

.show-1 {
    transform: rotateX(720deg) rotateZ(-720deg);
}

.show-6 {
    transform: rotateX(-900deg) rotateZ(1080deg);
}

.show-3 {
    transform: rotateY(-450deg) rotateZ(-1440deg);
}

.show-4 {
    transform: rotateY(810deg) rotateZ(720deg);
}

.show-5 {
    transform: rotateX(-810deg) rotateZ(-1080deg);
}

.show-2 {
    transform: rotateX(450deg) rotateZ(-720deg);
}

.two-1,
.three-1,
.four-1,
.five-1,
.six-1 {
    top: 20%;
    left: 20%;
}

.four-3,
.five-3,
.six-4 {
    top: 20%;
    left: 80%;
}

.one-1,
.three-2,
.five-5 {
    top: 50%;
    left: 50%;
}

.four-2,
.five-2,
.six-3 {
    top: 80%;
    left: 20%;
}

.two-2,
.three-3,
.four-4,
.five-4,
.six-6 {
    top: 80%;
    left: 80%;
}

.six-2 {
    top: 50%;
    left: 20%;
}

.six-5 {
    top: 50%;
    left: 80%;
}