
.roadmap {
    padding: 30px 50px;
  }
  
  .roadmap .point {
    display: flex;
    flex-direction: column;
    padding: 10px 50px;
    position: relative;
  }
  
  .roadmap .point:first-child .point-index {
    top: auto;
    bottom: 0;
    transform: translate(-50%, -35px) !important;
  }
  
  .roadmap .point:last-child .point-index {
    top: 0;
    transform: translate(-50%, 20px) !important;
  }
  
  .roadmap .point:not(:last-child) {
    border-bottom: 2px solid gold;
}

.roadmap .point:nth-child(odd) {
    align-items: flex-start;
    border-left: 2px solid gold;
}

.roadmap .point:nth-child(odd):not(:first-child) {
    border-left: 2px solid gold;
  }
  
  .roadmap .point:nth-child(odd) .point-index {
    left: 0;
    transform: translate(-50%, -50%);
  }
  
  .roadmap .point:nth-child(even) {
    align-items: flex-end;
  }
  
  .roadmap .point:nth-child(even):not(:last-child) {
    border-right: 2px solid gold;
  }
  
  .roadmap .point:nth-child(even) .point-index {
    right: 0;
    transform: translate(50%, -50%);
  }
  
  .roadmap .point .point-index {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background: #151317;
    border: 2px solid gold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: bold;
  }
  
  .roadmap .point .point-label {
    flex: 1 0 100%;
    width: 100%;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  @media (min-width: 641px) {
    .roadmap .point .point-label {
      flex: 1 0 50%;
      width: 50%;
    }
  }
  
  .roadmap .point .point-text {
    flex: 1 0 100%;
    width: 100%;
    font-size: 18px;
    color: #ffffff; /* Replace darken(#ffffff, 20%) with the actual color */
  }
  
  @media (min-width: 641px) {
    .roadmap .point .point-text {
      flex: 1 0 50%;
      width: 50%;
    }
  }